import service from '@/until/api'

export function _list(params) {
    return service({
        url: '/data-manage/operational/getAnalysisListData',
        method: 'get',
        params
    })
}
export function _hearNum(params) {
    return service({
        url: '/data-manage/operational/getAnalysisHeadData',
        method: 'get',
        params
    })
}
