<template>
  <div>
    <div class="analysis">
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <div class="item_icon">
              <i class="el-icon-truck"></i>
            </div>
            <div class="item_content">
              <div class="content_title">平均自动驾驶里程</div>
              <div class="content_text">
                <!-- <ul class="scroll_ul flex" style="border-bottom: none">
                  <ScrollNum
                    v-for="(num, idx) of headData.autoMileage.toString() || ''"
                    :key="idx"
                    as="li"
                    :i="num"
                    :delay="idx + 1"
                  />
                </ul> -->
                <span style="font-size: 32px">{{
                  headData.autoMileage || 'NULL'
                }}</span>
                <span>（KM/台）</span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <div class="item_icon">
              <i class="el-icon-guide"></i>
            </div>
            <div class="item_content">
              <div class="content_title">平均手柄接管次数</div>
              <div class="content_text">
                <!-- <ul style="display: flex; margin: 0; padding: 0">
                  <ScrollNum
                    v-for="(num, idx) of mileTotalCount"
                    :key="idx"
                    as="li"
                    :i="num"
                    :delay="idx + 1"
                  />
                </ul> -->
                <span style="font-size: 32px">{{
                  headData.gamepadTakeoverTimes || 'NULL'
                }}</span>
                <span>（次/台）</span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <div class="item_icon">
              <i class="el-icon-s-flag"></i>
            </div>
            <div class="item_content">
              <div class="content_title">高频次手柄接管路线</div>
              <div class="content_text">
                <span style="font-size: 30px">{{
                  headData.gamepadTakeoverLine || 'NULL'
                }}</span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <div class="item_icon">
              <i class="el-icon-s-flag"></i>
            </div>
            <div class="item_content">
              <div class="content_title">高频次手柄接管车辆</div>
              <div class="content_text">
                <span style="font-size: 30px">{{
                  headData.frequentGamepadVehicle || 'NULL'
                }}</span>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div>
      <el-form :inline="true" ref="ruleForm">
        <el-form-item>
          <el-select
            v-model="searchParams.parkName"
            :clearable="true"
            placeholder="请选择园区"
            class="centerSelInput"
            @change="SearchPageData"
          >
            <el-option
              v-for="item in ParkOption"
              :key="item.val"
              :label="item.text"
              :value="item.text"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-cascader
            clearable
            ref="cascaderVehicleTypes"
            placeholder="请选择车辆类型"
            class="centerSelInput centerInput"
            v-model="vehicleType"
            :options="CarTypeOption"
            :props="CarTypeOptionParams"
            :show-all-levels="false"
            @change="handleVehicleTypes"
          ></el-cascader>
        </el-form-item>

        <el-form-item>
          <el-input
            :clearable="true"
            v-model="searchParams.vehicleNumber"
            placeholder="请输入车辆编号"
            class="centerInput"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            placeholder="请选择日期"
            v-model="searchParams.dateTime"
            :clearable="false"
            type="date"
            value-format="yyyy-MM-dd"
            class="centerInput"
            @change="dateChange"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <button class="centerCzbtn" @click="SearchPageData">搜索</button>
        </el-form-item>
        <el-form-item>
          <button class="centerBtn resetbtn" type="button" @click="reset">
            重置
          </button>
        </el-form-item>
      </el-form>
    </div>
    <Table
      :Indexes="false"
      :data1="tableData"
      :columns1="columns"
      :total="total"
      @getAjax="getTableDate"
      :loading="loading"
      :page="page.currentPage"
      :size="page.pageSize"
    />
    <el-drawer
      title="查看手柄接管位置"
      :visible.sync="drawerShow"
      :before-close="handleClose"
      size="800px"
      destroy-on-close
    >
      <div id="lookContainer" style="height: 100%; width: 100%"></div>
    </el-drawer>
  </div>
</template>
<script>
import * as echarts from 'echarts'
import Table from '@/components/Table.vue'
import { _getParkList } from '../../api/global'
import { _list, _hearNum } from '../../api/dataAnalysis/carBusiness'
import { _CarTypeAll } from '../../api/selCar'
import { _boardGeneralView } from '../../api/dataAnalysis/analysis'
import ScrollNum from './components/scrollNum.vue'

export default {
  data() {
    return {
      row: null,
      cluster: null,
      drawerShow: false,
      tableData: [],
      topGeneralViewData: {
        vehileTotalCount: 0,
        mileTotalCount: 0,
        parkTotalCount: 0
      },
      vehicleType: [],
      searchParams: {
        parkName: '',
        vehicleNumber: '',
        dateTime: this.getNowFormatDate()
      },
      headData: {},
      ParkOption: [],
      CarTypeOption: [],
      CarTypeOptionParams: {
        children: 'children',
        label: 'typeName',
        value: 'typeName'
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },

      columns: [
        {
          label: '编号',
          prop: 'id',
          width: 60,
          render: (h, { index }) => {
            return h('div', [h('span', {}, index + 1)])
          }
        },
        {
          label: '运营日期',
          prop: 'dateTime'
        },
        {
          label: '所属园区',
          prop: 'parkName'
        },
        {
          label: '车辆编号',
          prop: 'vehicleNumber'
        },
        {
          label: '日累计里程（KM）',
          prop: 'dayTimeMileage'
        },
        {
          label: '自动驾驶里程（KM）',
          prop: 'dayTimeAutoMileage'
        },
        {
          label: '手柄状态里程（KM）',
          prop: 'dayTimeGamepadMileage'
        },
        {
          label: '手柄状态接管次数',
          prop: 'gamepadTakeoverTimes'
        },
        {
          label: '关联安全员',
          prop: 'associatedSaferRecords',
          render: (h, { row: { associatedSaferRecords } }) => {
            const arr = []
            associatedSaferRecords.map(item => {
              if (item.recordSafetyOfficer) {
                if (arr.indexOf(item.recordSafetyOfficer) == -1) {
                  arr.push(item.recordSafetyOfficer)
                }
              }
            })

            return h('div', [h('span', {}, arr.join(','))])
          }
        },
        {
          label: '操作',
          render: (h, { row }) => {
            return (
              <el-button
                disabled={!row.gamepadTakeoverTimes}
                type="text"
                onClick={() => this.viewPosition(row)}>
                查看手柄接管发生地
              </el-button>
            )
          }
        }
      ],
      total: 0,
      page: {
        currentPage: 1,
        pageSize: 10
      },
      loading: false,
      addedmap: null
    }
  },
  components: {
    ScrollNum,
    Table
  },
  computed: {
    vehileTotalCount() {
      const str = String(this.topGeneralViewData.vehileTotalCount)
      let arr = []
      for (let i = 0; i < str.length; i++) {
        arr.push(parseInt(str[i]))
      }
      return arr
    },
    mileTotalCount() {
      const str = String(this.topGeneralViewData.mileTotalCount)
      let arr = []

      for (let i = 0; i < str.length; i++) {
        arr.push(parseInt(str[i]))
      }
      return arr
    },
    parkTotalCount() {
      const str = String(this.topGeneralViewData.parkTotalCount)
      let arr = []
      for (let i = 0; i < str.length; i++) {
        arr.push(parseInt(str[i]))
      }
      return arr
    }
  },
  mounted() {
    this.getTopGeneralView()
    this.GetParkOption()
    this.GetCarTypeOption()
    this.getTableDate()
    this.getHearNum()
  },
  methods: {
    unique(arr) {
      return Array.from(new Set(arr))
    },
    viewPosition(row) {
      this.row = row
      this.drawerShow = true
      this.mapInit()
    },
    dateChange() {
      this.page.currentPage = 1
      this.getTableDate()
      this.getHearNum()
    },
    reset() {
      this.searchParams = {
        parkName: '',
        vehicleNumber: '',
        dateTime: this.getNowFormatDate()
      }
      this.page = {
        currentPage: 1,
        pageSize: 10
      }
      this.vehicleType = []
      this.getTableDate()
      this.getHearNum()
    },
    getNowFormatDate() {
      var date = new Date()
      date.setTime(date.getTime() - 24 * 60 * 60 * 1000)
      var seperator1 = '-'
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate
      return currentdate
    },
    async getHearNum() {
      const { data } = await _hearNum(this.searchParams)
      this.headData = data
    },
    handleClose() {
      this.drawerShow = false
    },
    addLine() {
      const { routeSiteMap } = this.row
      console.log(routeSiteMap)
      for (let key in routeSiteMap) {
        const arr = JSON.parse(routeSiteMap[key])
        arr.push(arr[0])
        this.CreateFence(arr)
        const poi = this.calculateCenter(arr)
        this.addedmap.setCenter([poi.lng, poi.lat])
      }
    },
    calculateCenter(lnglatarr) {
      let total = lnglatarr.length
      let X = 0,
        Y = 0,
        Z = 0

      for (let i = 0; i < total; i++) {
        let lng = (lnglatarr[i][0] * Math.PI) / 180
        let lat = (lnglatarr[i][1] * Math.PI) / 180
        let x, y, z

        x = Math.cos(lat) * Math.cos(lng)
        y = Math.cos(lat) * Math.sin(lng)
        z = Math.sin(lat)
        X += x
        Y += y
        Z += z
      }

      X = X / total
      Y = Y / total
      Z = Z / total

      let Lng = Math.atan2(Y, X)
      let Hyp = Math.sqrt(X * X + Y * Y)
      let Lat = Math.atan2(Z, Hyp)
      let CenterPoint = null

      CenterPoint = new AMap.LngLat(
        (Lng * 180) / Math.PI,
        (Lat * 180) / Math.PI
      )

      return CenterPoint
    },
    CreateFence(fence_list) {
      let nCount = fence_list.length
      let polylinePath = []
      for (let i = 0; i < nCount; i++) {
        polylinePath.push(new AMap.LngLat(fence_list[i][0], fence_list[i][1]))
      }
      let polyline = new AMap.Polyline({
        path: polylinePath, // 设置线覆盖物路径
        showDir: false,
        strokeColor: '#000', // 线颜色
        strokeWeight: 5, // 线宽
        strokeOpacity: 0.7,
        showDir: true
      })
      this.addedmap.add([polyline])
    },
    async mapInit() {
      await this.$nextTick()
      this.addedmap = new AMap.Map('lookContainer', {
        resizeEnable: true,
        center: [114.142827690973, 30.410643717448],
        zoom: 15
      })
      this.addLine()
      this.addPoint()
    },
    openInfo(list, position) {
      const info = ["<div style='line-height:14px;text-align:left'>"]
      list.map(({ recordTime, recordSafetyOfficer }) => {
        info.push('<div><div>' + recordTime + '</div> ')
        info.push(
          '<div style="margin-bottom:8px"><span>安全员：' +
            recordSafetyOfficer +
            '</span></div></div>'
        )
      })
      info.push('</div>')
      const infoWindow = new AMap.InfoWindow({
        content: info.join('<br/>'),
        autoMove: true,
        closeWhenClickMap: true,
        showShadow: true
      })
      infoWindow.open(this.addedmap, position)
    },
    closeInfo() {
      infoWindow.close()
    },
    addPoint() {
      const { associatedSaferRecords } = this.row
      const points = []
      associatedSaferRecords.map(item => {
        const {
          recordLongitude,
          recordLatitude,
          recordTime,
          recordSafetyOfficer
        } = item
        points.push({
          lnglat: [Number(recordLongitude), Number(recordLatitude)],
          recordTime,
          recordSafetyOfficer
        })
      })
      const markers = []
      for (var i = 0; i < points.length; i += 1) {
        const { recordTime, recordSafetyOfficer } = points[i]
        const ma = new AMap.Marker({
          position: points[i]['lnglat']
        })
        ma.setExtData({ recordTime, recordSafetyOfficer })
        ma.on('click', e => {
          this.openInfo([e.target.getExtData()], e.target.getPosition())
        })
        markers.push(ma)
      }
      const that = this
      this.addedmap.plugin(['AMap.MarkerClusterer'], function () {
        that.cluster = new AMap.MarkerClusterer(that.addedmap, markers, {
          gridSize: 60,
          zoomOnClick: false,
          styles: [
            {
              url: 'blue.png',
              size: new AMap.Size(32, 32),
              offset: new AMap.Pixel(-16, -16),
              textColor: '#fff'
            }
          ]
        })
      })

      this.cluster.on('click', function ({ markers, lnglat }) {
        const list = []
        markers.map(item => {
          list.push(item.getExtData())
        })
        that.openInfo(list, lnglat)
      })
    },
    async getTableDate(pagination) {
      this.loading = true
      if (pagination) {
        if (pagination.page) {
          this.page.currentPage = pagination.page
          this.page.pageSize = pagination.limit
        }
      }
      _list({
        limit: this.page.pageSize,
        pageNo: this.page.currentPage,
        ...this.searchParams
      }).then(({ data, paging }) => {
        this.tableData = data
        this.loading = false
        this.total = paging.total
      })
    },
    handleVehicleTypes() {
      // let objVehicleType = this.$refs.cascaderVehicleTypes.getCheckedNodes();
      // let arrVehicleType = [];
      // for (const vehicleTypeIds of this.searchParams.vehicleTypeIds) {
      //   for (const objType of objVehicleType) {
      //     if (vehicleTypeIds[1] === objType.value) {
      //       arrVehicleType.push(objType.label);
      //     }
      //   }
      // }
      this.page.currentPage = 1
      this.searchParams.vehicleType = this.vehicleType[1]
      this.SearchPageData()
      this.getHearNum()
    },
    SearchPageData() {
      this.getHearNum()
      this.getTableDate()
    },
    getTopGeneralView() {
      _boardGeneralView().then(res => {
        if (res.code === 0) {
          this.topGeneralViewData.vehileTotalCount = Number(
            res.data.vehicleAmount
          )
          this.topGeneralViewData.mileTotalCount = Number(res.data.totalMileage)
          this.topGeneralViewData.parkTotalCount = Number(res.data.totalParks)
        }
      })
    },
    //获取车辆类型选项
    async GetCarTypeOption() {
      let res = await _CarTypeAll()
      if (res.success) {
        this.CarTypeOption = res.data.list
      }
    },
    //获取园区选项
    async GetParkOption() {
      let res = await _getParkList()
      if (res.code === 0) {
        this.ParkOption = res.data
      }
    }
  }
}
</script>
<style>
.amap-info-close {
  display: none !important;
}
</style>
<style scoped lang="less">
.empty-title {
  padding-left: 30px;
  padding-top: 10px;
  font-weight: bold;
  color: #666;
  font-size: 16px;
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
}
.el-row {
  margin: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 20px 0;
  .item_icon {
    width: 80px;
    height: 80px;
    border-radius: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1683d9;
    i {
      font-size: 50px;
      color: #fff;
    }
    margin-right: 10px;
  }
  .item_content {
    padding: 30px 0;
  }
  .content_title {
    color: #909399;
  }
  .content_text {
    font-size: 32px;
    padding-top: 10px;
    display: flex;
    align-items: end;
    span {
      font-size: 16px;
    }
  }
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.analysis {
  margin-bottom: 20px;

  .analyAfter:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 35px;
    right: 0px;
  }
}
.top_item {
  background-color: #fff;
}

.circleBox {
  position: relative;
  top: 60px;
  display: flex;
  justify-content: center;
}

.el-progress__text {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  margin-left: 0px;
}

.el-progress__text > p:first-child {
  font-size: 16px;
  font-weight: bold;
}

.el-progress__text > p:last-child {
  font-size: 12px;
  margin-top: 8px;
}

.lineChart {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .lineChartdv {
    background-color: #fff;
    height: 330px;
    width: 49%;
    margin-bottom: 20px;
  }

  .lineChartdv1 {
    background-color: #fff;
    height: 330px;
    width: 50%;
    margin-bottom: 20px;
    position: relative;

    .goDetail {
      font-size: 12px;
      color: #1683d9;
      position: absolute;
      right: 50px;
      top: 60px;
      cursor: pointer;
      z-index: 1;
    }

    .chartWord {
      font-size: 15px;
      font-weight: 600;
      color: #666666;
      transform: translate(20px, 20px);
    }
  }
}
.flex-d-c {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.content_bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 20px;
  border-top: 1px solid #e3e3e3;
  width: 100%;
}
.content_item {
  background-color: #ddd;
  width: 49%;
  text-align: center;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
#chart4 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.pie_chart_item {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .tags {
    color: #fff;
    margin-bottom: 10px;
  }
}
.item_num {
  padding: 10px 0;
  font-size: 12px;
  color: #555;
}
.flex {
  display: flex;
}
.scroll_ul {
  padding: 0;
  margin: 0;
}
.business_block {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  align-items: center;
  padding: 0 10px;
}
.business_item {
  background-color: #fff;
  margin: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.business_percent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
}
.business_radio {
  height: 70px;
  display: flex;
  align-items: end;
  background-color: #fff;
  padding-left: 10px;
}
.break_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.break_block_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  text-align: center;
}
</style>
